@media only screen and (min-width: 2000px) {
    .App {
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 1500px) {
    .App {
        margin-left: 140px;
        margin-right: 140px;
    }

    h1 {
        font-size: 4rem;
    }
    h2 {
        font-size: 1.6rem;
    }

    .introContainer__text {
        top: -10rem;
    }
}

@media only screen and (max-width: 1300px) {
    .App {
        margin-left: 100px;
        margin-right: 100px;
    }
}

@media only screen and (max-width: 1200px) {
    .introContainer {
        max-height: 450px;
    }
}

@media only screen and (max-width: 1050px) {
    .App{
        margin-left: 60px;
        margin-right: 60px;
    }

    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 1.2rem;
    }
    h3 {
        font-size: 0.78rem;
    }

    .introContainer__text {
        top: -8rem;
    }

    .skillsContainer__skillIcon {
        margin: 15px;
    }
}

@media only screen and (max-width: 750px) {
    h1 {
        font-size: 2.5rem;
    }
    h2 {
        font-size: 1rem;
    }
    h3 {
        font-size: .65rem;
    }
    .introContainer__text {
        top: -6.5rem;
    }
}

@media only screen and (max-width: 850px) {
    .App{
        margin-left: 60px;
        margin-right: 60px;
    }
    
    .introContainer {
        height: 38vh;
        max-height: 320px;
    }
    
    .introContainer__image {
        height: 38vh;
        max-height: 300px;
    }
}

@media only screen and (max-width: 1000px) {
    .App {
        margin-left: 60px;
        margin-right: 60px;
    }
}

@media only screen and (max-width: 650px) {
    .App {
        margin-left: 20px;
        margin-right: 20px;
        min-width: 320px;
    }

    h1 {
        font-size: 1.6rem;
    }
    h2 {
        font-size: 0.72rem;
    }
    h3 {
        font-size: .72rem;
    }
    .introContainer__text {
        top: -4.5rem;
    }

    .headerContainer {
        margin-top: 3vh;
    }
    .headerContainer__leftLinks {
        margin-right: 20px;
    }

    .introContainer__bar {
        border-radius: 5px;
    }
    .introContainer {
        height: 20vh;
        margin-top: 3vh;
        min-height: 160px;
    }
    .introContainer__image {
        height: 20vh;
        min-height: 160px;
    }
}

@media only screen and (max-width: 400px) {
    .introContainer__text {
        right: 1rem;
    }
}

@media only screen and (max-width: 380px) {
    h1 {
        font-size: 1.4rem;
    }

    .introContainer__text {
        right: .5rem;
    }
}
.smallProject__container {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    padding-top: 2px;
    padding-bottom: 5px;
    margin-bottom: 15px;

    font-size: 2.5rem;
    border-radius: 5px;
    position: relative;
    background-color: #213E70;

    cursor: pointer;
}

.smallProject__title {    
    color: #fff;
    text-align: left;
    margin-left: 45px;
    max-width: 100%;
    margin-left: 20px;
    margin-top: -10px;
}

.smallProject__titleText {
    font-size: 1.3rem;
}

.smallProject__tools {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-right: 40px;
}

.smallProject__toolImage {
    width: auto;
    margin-right: min(40px, 6%);
    height: 35px;
}

.smallProject__githubLogo {
    width: 70px;
    height: auto;
    height: 25px;
    position: absolute;
    right: 20px;
    width: 25px;
    height: 25px;
}

.smallProject__description {
    position: absolute;
    color: #fff;
    font-weight: 200;
    white-space:normal
}

@media only screen and (max-width: 840px) {
    .smallProject__title {
        width: 100% !important;
    }
}

@media only screen and (max-width: 650px) {
    .smallProject__titleText {
        font-size: 1.3rem;
    }
    .smallProject__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    
        width: 100%;
        padding-top: 1px;
        padding-bottom: 5px;
        margin-bottom: 15px;
    
        font-size: 2.5rem;
        border-radius: 5px;
        position: relative;
        background-color: #213E70;
    }
    .smallProject__title {
        margin-left: 20px;
        margin-top: -10px;
        width: 100%;
    }
    .smallProject__tools {
        margin-left: 20px;
        margin-bottom: 10px;
    }
    .smallProject__toolImage {
        height: 25px;
    }
    .smallProject__githubContainer {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 25px;
        height: 25px;
    }
    .smallProject__githubLogo {
        height: 25px;
    }
}


.skillsContainer__skillContainer {
    border-radius: 5px;
    background-color: #E0FDFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: default;
}

.skillsContainer__skillIcon {
    width: 50px;
    height: auto;
    margin: 35px;
}

.skillsContainer__yearsText {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left:0;
    right:0;
    text-align: center;
    font-family: 'Shanti', sans-serif;
    color: #213E70;
}

@media only screen and (max-width: 1500px) {
    .skillsContainer__skillIcon {
        margin: 30px;
    }
}


@media only screen and (max-width: 1300px) {
    .skillsContainer__skillIcon {
        margin: 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .skillsContainer__skillIcon {
        margin: 20px;
    }
}

@media only screen and (max-width: 1050px) {
    .skillsContainer__skillIcon {
        margin: 15px;
    }
}

@media only screen and (max-width: 850px) {
    .skillsContainer {
        display: grid;
        grid-template-columns: auto auto auto auto;
        margin-top: 2.5vh;
        margin-bottom: 5vh;
        height: auto;
    }
    .skillsContainer__skillContainer {
        margin-top: 20px;
    }
}


@media only screen and (max-width: 650px) {
    .skillsContainer {
        margin-top: 1vh;
        margin-bottom: 3.5vh;
    }

}

@media only screen and (max-width: 500px) {
    .skillsContainer__skillIcon {
        margin: 20px;
        width: 40px;
    }
}

@media only screen and (max-width: 400px) {
    .skillsContainer__skillIcon {
        margin: 20px;
        width: 35px;
    }
}

@media only screen and (max-width: 380px) {
    .skillsContainer__skillIcon {
        margin: 15px;
        width: 30px;
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 0;
    display: inline;
    font-family: 'Shanti', sans-serif;
}

h1 {
    font-size: 5rem;
    font-weight: 400;
}

h2 {
    font-size: 2rem;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
}

h3 {
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
}

span {
    font-size: 1.2rem;
    font-family: 'Sarala', sans-serif;
    display: inline-block;
}

.shadow {
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}

.link {
    position: relative;
    cursor: pointer;
}

.bounding {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.App {
    text-align: center;
    margin-left: 185px;
    margin-right: 185px;
    max-width: 1950px;
    align-self: center;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.headerContainer {
    display: flex;
    flex-direction: row;
    margin-top: 8vh;
    justify-content: space-between;
}

.headerContainer__leftLinks {
    margin-right: 88px;
    display: 'flex';
    flex-direction: row;
}

.introContainer {
    display: flex;
    height: 58vh;
    max-height: 450px;
    margin-top: 5.7vh;
    display: 'flex';
    flex-direction: column-reverse;
}

.introContainer__bar {
    width: 100%;
    height: 42%;
    background-color: #81AEC6;
    border-radius: 5px 5px 5px 0px;
    position: relative;
}

.introContainer__image {
    height: 58vh;
    max-height: 450px;
    width: auto;
    position: absolute;
    bottom: 0;
    left: 0;
}

.introContainer__text {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -12rem;
    right: 2rem;
    text-align: left;
    cursor: default;
}

.introContainer__nameContainer {
    position: relative;
}

.introContainer__nameHighlight {
    position: absolute;
    bottom: 0;
    left: -5%;
    bottom: 5%;
    width: 110%;
    height: 35%;
    background-color: #CAE2E1;
    z-index: -1;
}

.aboutMeContainer {
    width: 92%;
    background-color: #213E70;
    border-radius: 5px;
    position: relative;
    padding-left: 4%;
    padding-right: 4%;
    overflow: hidden;
}

.aboutMeContainer__text {
    color: #fff;
    margin-left: 20px;
}
.skillsContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 24vh;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-height: 250px;
}

.smallProject__titleTest {
    font-size: 2rem;
}

.fadeIn {
	animation: fadeIn 1s;
  	opacity: 1;
}
.fadeOut {
    animation: fadeOut 1s;
    opacity: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-20px);
        -moz-transform: translatex(-20px);
        -o-transform: translatex(-20px);
        transform: translatex(-20px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}


.inLeft {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .6s;
    -moz-animation-duration: .6s;
    -o-animation-duration: .6s;
    animation-duration: .6s;
    /* -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s; */
    -o-animation-duration:.6s;
    /* animation-delay: 1s; */
}


@-webkit-keyframes fadeOutRight {
    from {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
    to {
        opacity:0;
        -webkit-transform: translatex(10px);
        -moz-transform: translatex(10px);
        -o-transform: translatex(10px);
        transform: translatex(10px);
    }
}
@-moz-keyframes fadeOutRight {
    from {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
    to {
        opacity:0;
        -webkit-transform: translatex(10px);
        -moz-transform: translatex(10px);
        -o-transform: translatex(10px);
        transform: translatex(10px);
    }
}
@keyframes fadeOutRight {
    from {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
    to {
        opacity:0;
        -webkit-transform: translatex(20px);
        -moz-transform: translatex(20px);
        -o-transform: translatex(20px);
        transform: translatex(20px);
    }
}
.outRight {
    -webkit-animation-name: fadeOutRight;
    -moz-animation-name: fadeOutRight;
    -o-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .6s;
    -moz-animation-duration: .6s;
    -o-animation-duration: .6s;
    animation-duration: .6s;
    -o-animation-duration:.6s;
}

@-webkit-keyframes collapse {
    from {
        margin-top: 3.5vh;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    to {
        height: 0px;
        margin-top: 0px;
    }
}
@-moz-keyframes collapse {
    from {
        margin-top: 3.5vh;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    to {
        height: 0px;
        margin-top: 0px;
    }
}
@keyframes collapse {
    from {
        margin-top: 3.5vh;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    to {
        height: 0px;
        margin-top: 0px;
    }
}
.collapse {
    -webkit-animation-name: collapse;
    -moz-animation-name: collapse;
    -o-animation-name: collapse;
    animation-name: collapse;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .6s;
    -moz-animation-duration: .6s;
    -o-animation-duration: .6s;
    animation-duration: .6s;
    -o-animation-duration:.6s;
}

@-webkit-keyframes expand {
    from {
        max-height: 0px;
        opacity: 0;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    to {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 3.5vh;
        max-height: none;
    }
}
@-moz-keyframes expand {
    from {
        max-height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    to {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 3.5vh;
        max-height: none;
    }
}
@keyframes expand {
    from {
        max-height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    to {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 3.5vh;
        max-height: none;
    }
}
.expand {
    -webkit-animation-name: expand;
    -moz-animation-name: expand;
    -o-animation-name: expand;
    animation-name: expand;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .8s;
    -moz-animation-duration: .8s;
    -o-animation-duration: .8s;
    animation-duration: .8s;
    -o-animation-duration:.8s;
}